<template>
  <div class="d-flex flex-column flex-root">
    <div id="kt_login" class=" login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white" :class="{
      'login-signin-on': state == 'signin',
      'login-forgot-on': state == 'forgot',
      'login-mfa-on': state == 'mfa',
    }">
      <!--begin::Aside-->
      <div class="login-aside d-flex flex-column flex-row-auto"
        style=" background: #373b44; background: -webkit-linear-gradient(to top, #4286f4, #373b44); background: linear-gradient(to top, #4286f4, #373b44);">
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img src="media/logos/atlasposting-mini.png" class="max-w-240" alt="" />
          </a>
        </div>
        <div class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div class="flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden mx-auto">
        <div v-show="showPages" class="content-show-page p-7">
          <component :is="page" v-if="page" back @back="back"></component>
        </div>
        <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-9 mx-auto">
          <div class="d-flex flex-column-fluid flex-center">
            <!--begin::Signin-->
            <div class="login-form login-signin">
              <form id="kt_login_signin_form" class="form" novalidate="novalidate">
                <div class="pb-13 pt-lg-0 pt-5 text-center">
                  <h3 class=" font-weight-bold font-size-h4 font-size-h1-lg text-primary">
                    {{ $t("Welcome to Atlasposting") }}
                  </h3>
                  <p class="text-muted font-weight-bold">{{ $t("Enter your username and password") }}</p>
                </div>
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bold text-primary"> {{ $t("Email") }}</label>
                  <div id="example-input-group-1" label="" label-for="example-input-1">
                    <input ref="email" v-model="form.email"
                      class=" form-control form-control-solid h-auto py-7 px-6 rounded-lg" type="text" name="email" />
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between mt-n5">
                    <label class="font-size-h6 font-weight-bold text-primary pt-5">{{ $t("Password") }}</label>
                  </div>
                  <div id="example-input-group-2" label="" label-for="example-input-2" class="d-flex rounded-lg">
                    <input ref="password" v-model="form.password"
                      class=" form-control form-control-solid h-auto py-7 px-6 rounded-lg pwd-field" xtype="password"
                      name="password" autocomplete="off" :type="pwdFieldShow ? 'text' : 'password'" />
                    <b-button variant="outline" class="show-button" tabindex="-1"
                      @click="() => (pwdFieldShow = !pwdFieldShow)">
                      <b-icon :icon="pwdFieldShow ? 'eye-slash' : 'eye-fill'" class="h2 m-auto "></b-icon>
                    </b-button>
                  </div>
                </div>
                <div class="pb-lg-0 pb-5 d-flex justify-content-between align-items-center">
                  <a id="kt_login_forgot" style="cursor: pointer;"
                    class="text-primary text-hover-primary d-inline-block" @click="showForm('forgot')">
                    {{ $t("Forgot Password ?") }}
                  </a>
                  <button ref="kt_login_signin_submit" type="submit"
                    class=" btn btn-primary font-weight-bold px-12 py-4 my-3">
                    {{ $t("Sign In") }}
                  </button>
                </div>
              </form>
            </div>
            <!--end::Signin-->
            <!--begin::Forgot-->
            <div class="login-form login-forgot">
              <!--begin::Form-->
              <form id="kt_login_forgot_form" ref="kt_login_forgot_form" class="form" novalidate="novalidate">
                <div class="pb-13 pt-lg-0 pt-5">
                  <h3 class=" font-weight-bold text-primary font-size-h4 font-size-h1-lg">
                    {{ $t("Forgotten Password ?") }}
                  </h3>
                  <p class="text-muted font-weight-bold font-size-h4">
                    {{ $t("Enter your email to reset your password") }}
                  </p>
                </div>
                <div class="form-group">
                  <input v-model="forgotMail"
                    class=" form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6" type="email"
                    :placeholder="$t('Email')" name="email" autocomplete="off" />
                </div>
                <div class="form-group d-flex flex-wrap pb-lg-0">
                  <button id="kt_login_forgot_submit" type="submit"
                    class=" btn btn-primary font-weight-bold font-size-h6 px-8 py-4 my-3 mr-4"
                    @click="changePwdRequest()">
                    {{ $t("Submit") }}
                  </button>
                  <button id="kt_login_forgot_cancel" type="button"
                    class=" btn btn-secondary font-weight-bold font-size-h6 px-8 py-4 my-3" @click="showForm('signin')">
                    {{ $t("Cancel") }}
                  </button>
                </div>
              </form>
            </div>
            <!--end::Forgot-->
            <div class="login-form login-mfa">
              <form id="kt_login_mfa_form" ref="kt_login_mfa_form" class="form" novalidate="novalidate">
                <div class="pb-13 pt-lg-0 pt-5">
                  <h3 class="
                    font-weight-bolder
                    text-primary
                    font-size-h4 font-size-h1-lg
                  ">
                    {{ $t("Multi-factor authentication") }}
                  </h3>
                  <p class="text-muted font-weight-bold font-size-h4">
                    {{ mfaForm.method === 'app' ? $t("Please insert the confirmation code from your authenticator app.")
      : mfaForm.method === 'sms_api' ? $t("Please insert the confirmation code we've sent you by SMS.") :
        $t("Please insert the confirmation code we've sent you by Email.")
                    }}
                  </p>
                  <span v-if="mfaForm.method === 'sms_api' || mfaForm.method === 'email'">
                    <a href="#" @click.prevent="resendCode()">{{ $t("Didn't receive the code?") }}</a>
                  </span>
                </div>
                <div class="form-group">
                  <input v-model="mfaForm.code"
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                    :placeholder="$t('XXXXXX')" name="code" autocomplete="off" />
                </div>
                <div class="form-group d-flex flex-wrap pb-lg-0 mb-0">
                  <button id="kt_login_mfa_submit" ref="kt_login_mfa_submit" type="submit"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4" @click="loginMfa">
                    {{ $t("Submit") }}
                  </button>
                  <button id="kt_login_mfa_cancel" type="button"
                    class=" btn btn-secondary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')">
                    {{ $t("Cancel") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--begin::Content footer-->
        <div class="d-flex justify-content-center align-items-end p-7 py-lg-0">
          <a href="https://arlettipartners.com/contattaci" target="_blank"
            class="text-primary mx-5 font-weight-bold mb-8">
            {{ $t("Contact Us") }}
          </a>
          <a href="#terms" class="text-primary mx-5 font-weight-bold mb-8" @click="showPage('terms')">
            {{ $t("Terms Conditions") }}
          </a>
          <a href="#general" class="text-primary mx-5 font-weight-bold mb-8" @click="showPage('general')">
            {{ $t("General Conditions") }}
          </a>
          <a href="#privacy" class="text-primary mx-5 font-weight-bold mb-8" @click="showPage('privacy')">
            {{ $t("Privacy Policy") }}
          </a>
          <a href="#cookie" class="text-primary mx-5 font-weight-bold mb-8" @click="showPage('cookie')">
            {{ $t("Cookie Policy") }}
          </a>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
import AuthService from "@/core/services/auth.service";
import { backendErrorSwal } from "@/core/helpers/swal";

export default {
  name: "Login",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      pwdFieldShow: false,
      form: {
        email: "",
        password: "",
      },
      forgotMail: "",
      mfaForm: {
        ephemeral_token: "",
        method: "",
        code: "",
      },
      showPages: false,
      page: null,
    };
  },

  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/login-visual.png";
    },
  },

  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");
    const mfa_form = KTUtil.getById("kt_login_mfa_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t("Email is required"),
            },
            regexp: {
              regexp: '^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$',
              message: this.$t("The value is not a valid email address"),
            }
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: this.$t("Password is required"),
            },
            stringLength: {
              min: 8,
              message: this.$t("The password must have at least 8 characters"),
            }
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t("Email is required"),
            },
            regexp: {
              regexp: '^[^@\\s]+@([^@\\s]+\\.)+[^@\\s]+$',
              message: this.$t("The value is not a valid email address"),
            }
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv3 = formValidation(mfa_form, {
      fields: {
        code: {
          validators: {
            notEmpty: {
              message: this.$t("Code required"),
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", async () => {
      var email = this.form.email;
      var password = this.form.password;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      try {
        const res = await this.login({ email, password });

        if (res?.ephemeral_token) {
          this.mfaForm.ephemeral_token = res.ephemeral_token;
          this.mfaForm.method = res.method;
          this.showForm("mfa");
          return;
        }

        this.goToDashboard();
      } catch (err) {
        // Check if the error is a network error
        if (!err.response) {
          // This is likely a network error
          await backendErrorSwal(err, this.$t("Check your network connection or try again later, if the error persist, contact the support"), this.$t("Network Error"));
        } else {
          // The error is from the server response
          if (err.response.status === 400) {
            // Handle 400 status code specifically
            this.fv.emit("core.form.invalid");
          } else {
            // Handle other statuses or perform a general error handling
            console.error("Error:", err.message);
            await backendErrorSwal(err, this.$t("An error occurred while trying to login, please try again later or contact the support"), this.$t("Error"));
          }
        }
      } finally {
        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
      }
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: this.$t("Login error"),
        text: this.$t("Invalid credentials. Please check your email and password and try again."),
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    // check path
    if (this.$route.hash) {
      if (['#terms', '#general', '#privacy', '#cookie'].includes(this.$route.hash)) {
        this.showPage(this.$route.hash.replace('#', ''))
      }
    }
  },

  methods: {
    ...mapActions("auth", ["login", "verifyMFA"]),

    goToReset(route) {
      this.$router.push(route);
    },

    async changePwdRequest() {
      await this.fv2.validate().then((status) => {
        if (status === "Valid") {
          try {
            AuthService.pwdMailRequest(this.forgotMail);
            Swal.fire({
              title: "",
              text: this.$t("If the email exists, a reset link will be sent to it!"),
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } catch (e) {
            backendErrorSwal(e);
          }
        } else {
          Swal.fire({
            title: "",
            text: this.$t("Please, provide correct Data!"),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      });
    },

    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(KTUtil.getById(form_name), "animate__animated animate__backInUp");
    },

    goToDashboard() {
      // check if there was an attempted access path
      const path = this.$route.query.redirect;

      // redirect the user to the initial attempted access path or to '/dashboard'
      this.$router.push(path || '/dashboard');
    },

    async loginMfa() {
      const submitButton = this.$refs["kt_login_mfa_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      try {
        await this.verifyMFA({ code: this.mfaForm.code, ephemeralToken: this.mfaForm.ephemeral_token });
        this.goToDashboard();
      } catch (err) {
        this.fv.emit("core.form.invalid");
      } finally {
        submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
      }
    },
    async resendCode() {
      try {
        await this.login({ email: this.form.email, password: this.form.password });
        await Swal.fire({
          title: "",
          text: this.$t("Code resent!"),
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      } catch (err) {
        await backendErrorSwal(err);
      }
    },
    showPage(page) {
      this.showPages = true
      switch (page) {
        case 'terms':
          this.page = () => import('@/view/pages/static/TermCondition.vue')
          break;
        case 'general':
          this.page = () => import('@/view/pages/static/GeneralConditions.vue')
          break;
        case 'privacy':
          this.page = () => import('@/view/pages/static/PrivacyPolicy.vue')
          break;
        default:
          this.page = () => import('@/view/pages/static/CookiePolicy.vue')
          break;
      }

    },
    back() {
      history.replaceState({}, document.title, window.location.href.split('#')[0]);
      this.showPages = false
    }
  },
};
</script>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";

.custom-input-group {
  background-color: #f3f6f9;
}

.max-w-240 {
  max-width: 300px;
}

.show-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #f3f6f9;
  border-bottom-right-radius: 0.85rem !important;
  border-top-right-radius: 0.85rem !important;
}

.pwd-field {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.content-show-page {
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: white;
}
</style>
