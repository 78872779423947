var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root"
  }, [_c('div', {
    staticClass: "login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white",
    class: {
      'login-signin-on': _vm.state == 'signin',
      'login-forgot-on': _vm.state == 'forgot',
      'login-mfa-on': _vm.state == 'mfa'
    },
    attrs: {
      "id": "kt_login"
    }
  }, [_c('div', {
    staticClass: "login-aside d-flex flex-column flex-row-auto",
    staticStyle: {
      "background": "linear-gradient(to top, #4286f4, #373b44)"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center",
    style: {
      backgroundImage: "url(".concat(_vm.backgroundImage, ")")
    }
  })]), _c('div', {
    staticClass: "flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden mx-auto"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showPages,
      expression: "showPages"
    }],
    staticClass: "content-show-page p-7"
  }, [_vm.page ? _c(_vm.page, {
    tag: "component",
    attrs: {
      "back": ""
    },
    on: {
      "back": _vm.back
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-9 mx-auto"
  }, [_c('div', {
    staticClass: "d-flex flex-column-fluid flex-center"
  }, [_c('div', {
    staticClass: "login-form login-signin"
  }, [_c('form', {
    staticClass: "form",
    attrs: {
      "id": "kt_login_signin_form",
      "novalidate": "novalidate"
    }
  }, [_c('div', {
    staticClass: "pb-13 pt-lg-0 pt-5 text-center"
  }, [_c('h3', {
    staticClass: "font-weight-bold font-size-h4 font-size-h1-lg text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("Welcome to Atlasposting")) + " ")]), _c('p', {
    staticClass: "text-muted font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("Enter your username and password")))])]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "font-size-h6 font-weight-bold text-primary"
  }, [_vm._v(" " + _vm._s(_vm.$t("Email")))]), _c('div', {
    attrs: {
      "id": "example-input-group-1",
      "label": "",
      "label-for": "example-input-1"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.email,
      expression: "form.email"
    }],
    ref: "email",
    staticClass: "form-control form-control-solid h-auto py-7 px-6 rounded-lg",
    attrs: {
      "type": "text",
      "name": "email"
    },
    domProps: {
      "value": _vm.form.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "email", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between mt-n5"
  }, [_c('label', {
    staticClass: "font-size-h6 font-weight-bold text-primary pt-5"
  }, [_vm._v(_vm._s(_vm.$t("Password")))])]), _c('div', {
    staticClass: "d-flex rounded-lg",
    attrs: {
      "id": "example-input-group-2",
      "label": "",
      "label-for": "example-input-2"
    }
  }, [(_vm.pwdFieldShow ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    ref: "password",
    staticClass: "form-control form-control-solid h-auto py-7 px-6 rounded-lg pwd-field",
    attrs: {
      "xtype": "password",
      "name": "password",
      "autocomplete": "off",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.password) ? _vm._i(_vm.form.password, null) > -1 : _vm.form.password
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.form.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "password", $$c);
        }
      }
    }
  }) : (_vm.pwdFieldShow ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    ref: "password",
    staticClass: "form-control form-control-solid h-auto py-7 px-6 rounded-lg pwd-field",
    attrs: {
      "xtype": "password",
      "name": "password",
      "autocomplete": "off",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.form.password, null)
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.form, "password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    ref: "password",
    staticClass: "form-control form-control-solid h-auto py-7 px-6 rounded-lg pwd-field",
    attrs: {
      "xtype": "password",
      "name": "password",
      "autocomplete": "off",
      "type": _vm.pwdFieldShow ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "password", $event.target.value);
      }
    }
  }), _c('b-button', {
    staticClass: "show-button",
    attrs: {
      "variant": "outline",
      "tabindex": "-1"
    },
    on: {
      "click": function click() {
        return _vm.pwdFieldShow = !_vm.pwdFieldShow;
      }
    }
  }, [_c('b-icon', {
    staticClass: "h2 m-auto",
    attrs: {
      "icon": _vm.pwdFieldShow ? 'eye-slash' : 'eye-fill'
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "pb-lg-0 pb-5 d-flex justify-content-between align-items-center"
  }, [_c('a', {
    staticClass: "text-primary text-hover-primary d-inline-block",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "id": "kt_login_forgot"
    },
    on: {
      "click": function click($event) {
        return _vm.showForm('forgot');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Forgot Password ?")) + " ")]), _c('button', {
    ref: "kt_login_signin_submit",
    staticClass: "btn btn-primary font-weight-bold px-12 py-4 my-3",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Sign In")) + " ")])])])]), _c('div', {
    staticClass: "login-form login-forgot"
  }, [_c('form', {
    ref: "kt_login_forgot_form",
    staticClass: "form",
    attrs: {
      "id": "kt_login_forgot_form",
      "novalidate": "novalidate"
    }
  }, [_c('div', {
    staticClass: "pb-13 pt-lg-0 pt-5"
  }, [_c('h3', {
    staticClass: "font-weight-bold text-primary font-size-h4 font-size-h1-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("Forgotten Password ?")) + " ")]), _c('p', {
    staticClass: "text-muted font-weight-bold font-size-h4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Enter your email to reset your password")) + " ")])]), _c('div', {
    staticClass: "form-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forgotMail,
      expression: "forgotMail"
    }],
    staticClass: "form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6",
    attrs: {
      "type": "email",
      "placeholder": _vm.$t('Email'),
      "name": "email",
      "autocomplete": "off"
    },
    domProps: {
      "value": _vm.forgotMail
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.forgotMail = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group d-flex flex-wrap pb-lg-0"
  }, [_c('button', {
    staticClass: "btn btn-primary font-weight-bold font-size-h6 px-8 py-4 my-3 mr-4",
    attrs: {
      "id": "kt_login_forgot_submit",
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        return _vm.changePwdRequest();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]), _c('button', {
    staticClass: "btn btn-secondary font-weight-bold font-size-h6 px-8 py-4 my-3",
    attrs: {
      "id": "kt_login_forgot_cancel",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.showForm('signin');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])])])]), _c('div', {
    staticClass: "login-form login-mfa"
  }, [_c('form', {
    ref: "kt_login_mfa_form",
    staticClass: "form",
    attrs: {
      "id": "kt_login_mfa_form",
      "novalidate": "novalidate"
    }
  }, [_c('div', {
    staticClass: "pb-13 pt-lg-0 pt-5"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-primary font-size-h4 font-size-h1-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("Multi-factor authentication")) + " ")]), _c('p', {
    staticClass: "text-muted font-weight-bold font-size-h4"
  }, [_vm._v(" " + _vm._s(_vm.mfaForm.method === 'app' ? _vm.$t("Please insert the confirmation code from your authenticator app.") : _vm.mfaForm.method === 'sms_api' ? _vm.$t("Please insert the confirmation code we've sent you by SMS.") : _vm.$t("Please insert the confirmation code we've sent you by Email.")) + " ")]), _vm.mfaForm.method === 'sms_api' || _vm.mfaForm.method === 'email' ? _c('span', [_c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.resendCode();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Didn't receive the code?")))])]) : _vm._e()]), _c('div', {
    staticClass: "form-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mfaForm.code,
      expression: "mfaForm.code"
    }],
    staticClass: "form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6",
    attrs: {
      "placeholder": _vm.$t('XXXXXX'),
      "name": "code",
      "autocomplete": "off"
    },
    domProps: {
      "value": _vm.mfaForm.code
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.mfaForm, "code", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group d-flex flex-wrap pb-lg-0 mb-0"
  }, [_c('button', {
    ref: "kt_login_mfa_submit",
    staticClass: "btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4",
    attrs: {
      "id": "kt_login_mfa_submit",
      "type": "submit"
    },
    on: {
      "click": _vm.loginMfa
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]), _c('button', {
    staticClass: "btn btn-secondary font-weight-bolder font-size-h6 px-8 py-4 my-3",
    attrs: {
      "id": "kt_login_mfa_cancel",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.showForm('signin');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])])])])])]), _c('div', {
    staticClass: "d-flex justify-content-center align-items-end p-7 py-lg-0"
  }, [_c('a', {
    staticClass: "text-primary mx-5 font-weight-bold mb-8",
    attrs: {
      "href": "https://arlettipartners.com/contattaci",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Contact Us")) + " ")]), _c('a', {
    staticClass: "text-primary mx-5 font-weight-bold mb-8",
    attrs: {
      "href": "#terms"
    },
    on: {
      "click": function click($event) {
        return _vm.showPage('terms');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Terms Conditions")) + " ")]), _c('a', {
    staticClass: "text-primary mx-5 font-weight-bold mb-8",
    attrs: {
      "href": "#general"
    },
    on: {
      "click": function click($event) {
        return _vm.showPage('general');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("General Conditions")) + " ")]), _c('a', {
    staticClass: "text-primary mx-5 font-weight-bold mb-8",
    attrs: {
      "href": "#privacy"
    },
    on: {
      "click": function click($event) {
        return _vm.showPage('privacy');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Privacy Policy")) + " ")]), _c('a', {
    staticClass: "text-primary mx-5 font-weight-bold mb-8",
    attrs: {
      "href": "#cookie"
    },
    on: {
      "click": function click($event) {
        return _vm.showPage('cookie');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cookie Policy")) + " ")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column-auto flex-column pt-lg-40 pt-15"
  }, [_c('a', {
    staticClass: "text-center mb-10",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "max-w-240",
    attrs: {
      "src": "media/logos/atlasposting-mini.png",
      "alt": ""
    }
  })])]);

}]

export { render, staticRenderFns }